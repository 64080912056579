
import svgLinesLeft from "@/assets/icons/broadcast_lines_left.svg";
import svgLinesRight from "@/assets/icons/broadcast_lines_right.svg";
import svgLinesMob from "@/assets/icons/broadcast_lines_mob.svg";
import { defineComponent, ref } from "vue";
import HomeBroadcastVideoPlaceholder from "./HomeBroadcastVideoPlaceholder.vue";
import svgScroll from "@/assets/icons/header_scroll.svg";
import YouTube from "vue3-youtube";
import Vivus from "vivus";
export default defineComponent({
  components: {
    YouTube,
    svgLinesLeft,
    svgLinesRight,
    HomeBroadcastVideoPlaceholder,
    svgScroll,
    svgLinesMob,
  },
  setup() {
    const isPlaying = ref(false);
    const play = () => {
      isPlaying.value = true;
    };
    const youtube = ref();
    const onReady = () => {
      youtube.value.playVideo();
    };
    window.addEventListener("load", () => {
      const els = [
        ...document.querySelectorAll(".home-broadcast-video__lines"),
      ];
      els.forEach((el) => {
        new Vivus(el, { duration: 100 }, function (obj) {
          obj.el.classList.add("finished");
        });
      });
    });
    return { isPlaying, play, youtube, onReady };
  },
});
