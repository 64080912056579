
import ShowFull from "@/components/ShowFull/ShowFull.vue";
import { defineComponent, ref } from "vue";
export default defineComponent({
  components: { ShowFull },
  setup() {
    const isOpen = ref(false);
    const toggle = () => {
      isOpen.value = !isOpen.value;
    };
    return { isOpen, toggle };
  },
});
