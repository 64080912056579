
import { defineComponent } from "vue";
import svgPlus from "@/assets/icons/plus.svg";

export default defineComponent({
  components: { svgPlus },

  props: {
    open: Boolean,
    text: String,
  },
  setup(props, { emit }) {
    const toggle = () => {
      emit("update:open", !props.open);
    };
    return { toggle };
  },
});
