
import { defineComponent } from "vue";
import svgMicrophone from "@/assets/icons/microphone.svg";
import svgCircle from "@/assets/icons/test_circle.svg";
export default defineComponent({
  components: { svgMicrophone, svgCircle },
  props: {
    image: String,
    name: String,
    position: String,
  },
  setup() {
    return {};
  },
});
