
import { computed, defineComponent, ref } from "vue";
import HomeSpeakersList from "./HomeSpeakersList.vue";
import HomeSpeakersSlider from "./HomeSpeakersSlider.vue";
import speakersData from "./speakers-data";

export default defineComponent({
  components: { HomeSpeakersSlider, HomeSpeakersList },
  setup() {
    const items = computed(() => {
      return speakersData;
    });
    const showSlider = ref(true);
    const checkResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        showSlider.value = false;
      } else {
        showSlider.value = true;
      }
    };
    window.addEventListener("resize", checkResize);
    checkResize();
    return { items, showSlider };
  },
});
