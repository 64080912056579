
import { computed, defineComponent } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import SpeakerCard from "@/components/SpeakerCard.vue";
import "swiper/swiper-bundle.min.css";
import { SwiperOptions } from "swiper";
import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);
import svgArrowLeft from "@/assets/icons/arrow_left.svg";
import svgArrowRight from "@/assets/icons/arrow_right.svg";
import speakersData from "./speakers-data";
export default defineComponent({
  components: { Swiper, SwiperSlide, SpeakerCard, svgArrowLeft, svgArrowRight },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const sliderOpts: SwiperOptions = {
      slidesPerView: "auto",
      spaceBetween: 40,
      navigation: {
        prevEl: ".home-speakers-slider__arrow-left",
        nextEl: ".home-speakers-slider__arrow-right",
      },
      breakpoints: {
        992: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      },
    };
    return { sliderOpts };
  },
});
