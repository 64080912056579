export default [
  {
    path: "/result",
    component: () => import("@/views/Result.vue"),
  },
  {
    path: "/result2",
    component: () => import("@/views/Result2.vue"),
  },
];
