export default [
  {
    image: require("@/assets/img/speakers/1.jpeg"),
    name: "Горьков Сергей Николаевич",
    position: "Генеральный директор <br> Председатель Правления АО”Росгеология”",
  },
  {
    image: require("@/assets/img/speakers/2.jpeg"),
    name: "Эпов Михаил Иванович",
    position: "Академик РАН, профессор, Ученый-геофизик. АО «СНИИГГиМС»",
  },
  {
    image: require("@/assets/img/speakers/3.jpeg"),
    name: "Элердова Милана  Александровна",
    position: "Генеральный директор АО «ТЕРРА ТЕХ». <br> Госкорпорация «Роскосмос» (дочернее предприятие АО «Российские космические системы»)",
  },
  {
    image: require("@/assets/img/speakers/4.jpeg"),
    name: "Биктимиров Рустам Рамилевич ",
    position:
      "Вице-президент, Цифровизация и Интеграция,  Шлюмберже Россия и Центральная Азия",
  },
  {
    image: require("@/assets/img/speakers/5.jpeg"),
    name: "Насипов Ленар Альфатович ",
    position:
      "Руководитель направления по сейсморазведке, Цифровизация и Интеграция, Шлюмберже Россия и Центральная Азия ",
  },
   {
    image: require("@/assets/img/speakers/6.jpeg"),
    name: "Федоров Алексей Константинович",
    position:
      "Руководитель научной группы Российского квантового центра",
  },
  {
    image: require("@/assets/img/speakers/7.jpeg"),
    name: "Шарипов Ян Галимович",
    position: "Генеральный директор ООО «ТНГ-групп»",
  },
  {
    image: require("@/assets/img/speakers/8.jpeg"),
    name: "Бородич Александр  Сергеевич",
    position:
      "Российский венчурный инвестор, серийный предприниматель, специалист в области инноваций и блокчейн технологий, стратег в области цифровых медиа. Основатель платформы Universa Blockchain.",
  },
  // {
  //   image: require("@/assets/img/speakers/9.jpeg"),
  //   name: "Толкачев Владимир Михайлович",
  //   position: "Президент ПАО «ГЕОТЕК Сейсморазведка»",
  // },
  {
    image: require("@/assets/img/speakers/10.jpeg"),
    name: "Борисов Василий  Валерьевич",
    position: "Генеральный директор ООО «Серч Сентрик»",
  },
  {
    image: require("@/assets/img/speakers/11.jpeg"),
    name: "Веденисов Михаил  Николаевич",
    position: "Компания 1С <br> Директор по специальным проектам",
  },
  {
    image: require("@/assets/img/speakers/12.jpeg"),
    name: "Жухин Айдос Утегенович",
    position: "Зала Аэро групп - Руководитель проектов. Группа компаний входит в концерн \"Калашников\"",
  },
  {
    image: require('@/assets/img/speakers/13.jpeg'),
    name: 'Петров Евгений Игнатьевич',
    position: 'Временно исполняющий обязанности руководителя Федерального агентства по недропользованию'
  }
];
