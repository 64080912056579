
import { defineComponent } from "vue";
import svgPlay from "@/assets/icons/play.svg";
import svgPlayCircle from "@/assets/icons/play_circle.svg";
export default defineComponent({
  components: { svgPlay, svgPlayCircle },
  setup() {
    return {};
  },
});
