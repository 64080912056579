
import { defineComponent } from "vue";
import AFooterBottom from "./AFooterBottom.vue";
import AFooterSoc from "./AFooterSoc.vue";
import svgDanger from "@/assets/icons/danger.svg";
import svgSitemap from "@/assets/icons/sitemap.svg";
export default defineComponent({
  components: { AFooterBottom, AFooterSoc, svgDanger, svgSitemap },
  setup() {
    return {};
  },
});
