<template>
  <div class="home-header-description">
    <div>
      <div>
        15 июля АО «Росгеология» отмечает свой десятилетний юбилей. <br />
        Это десятилетие было во многом переломным, но очень важным в истории
        Компании и геологоразведки в целом. Предпринятые нами шаги по реализации
        новой стратегии, трансформация бизнес-процессов, развитию производства,
        позволили Холдингу не только дать достойный ответ вызовам мирового
        кризиса, но и сделать существенный шаг вперед по пути укрепления
        бизнеса, внедрения новых технологий и современных решений.
      </div>
      <div v-if="isOpen">
        <br />
        Росгео вступает в новое десятилетие, новую эру геологоразведки, где
        дальнейшее развитие немыслимо в отрыве от современных
        высокотехнологичных трендов — использования космических технологий,
        искусственного интеллекта, робототехники, квантовых вычислений, блокчейн
        и многих других достижений современной науки. <br /> <br>
        23 июля в 10:00 в ходе онлайн-конференции «Геология будущего»,
        организованной к юбилею Компании, наши гости раскроют свой взгляд на
        геологию будущего, расскажут об основных тенденциях грядущего
        десятилетия и перспективах практического применения новых
        высокотехнологичных трендов в геологоразведке.
      </div>
    </div>
    <show-full
      class="mt-7 sm:mt-10"
      :text="'Читать полностью'"
      v-model:open="isOpen"
    />
  </div>
</template>

<script lang="ts">
import ShowFull from "@/components/ShowFull/ShowFull.vue";
import { defineComponent, ref } from "vue";
export default defineComponent({
  components: { ShowFull },
  setup() {
    const isOpen = ref(false);
    const toggle = () => {
      isOpen.value = !isOpen.value;
    };
    return { isOpen, toggle };
  },
});
</script>

<style lang="postcss">
.home-header-description {
  @apply text-grey-light text-lg max-w-[625px] mx-auto mt-6 sm:mt-[70px] sm:text-lg;
}
</style>
