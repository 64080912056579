
import { defineComponent, onMounted } from "vue";
import svgLinesLeft from "@/assets/icons/header_bg/lines_left.svg";
import svgLinesRight from "@/assets/icons/header_bg/lines_right.svg";
import svgLinesLeftBlur from "@/assets/icons/header_bg/lines_left_blur.svg";
import svgLinesRightBlur from "@/assets/icons/header_bg/lines_right_blur.svg";
import Vivus from "vivus";
export default defineComponent({
  components: {
    svgLinesLeft,
    svgLinesLeftBlur,
    svgLinesRight,
    svgLinesRightBlur,
  },
  setup() {
    window.addEventListener("load", () => {
      const els = [...document.querySelectorAll(".animated-lines")];
      els.forEach((el) => {
        new Vivus(el, { duration: 200, start: "autostart" }, function (obj) {
          obj.el.classList.add("finished");
        });
      });
    });
    return {};
  },
});
