
import Vote from "@/components/Votes/Vote.vue";
import { computed, defineComponent, inject, ref } from "vue";
import { useAppAxios } from "@/composables/useAppAxios";
import svgVoteCheckmark from "@/assets/icons/vote_checkmark.svg";
import { useCookie } from "@vue-composable/cookie";
export default defineComponent({
  props: {
    votes: {
      type: Array,
      default: () => [],
    },
  },
  components: { Vote, svgVoteCheckmark },
  setup(props, { emit }) {
    const votes = computed(() => props.votes);
    const activeVotes = ref({});
    const { exec: _sendAnswers, data: sendAnswersData } = useAppAxios();
    const sendAnswers = async () => {
      const answers = Object.keys(activeVotes.value).filter(
        (id) => !!activeVotes.value[id]
      );
      const toSend = {
        answers,
      };

      await _sendAnswers({
        method: "POST",
        url: "/vote/",
        data: toSend,
      });
      
      emit("send", answers);
    };
    return { activeVotes, sendAnswers };
  },
});
