
import { defineComponent, onMounted, ref } from "vue";
import Rellax from "rellax";
import Spinner from "@/components/Spinner/Spinner.vue";
export default defineComponent({
  name: "App",
  components: { Spinner },
  setup() {
    const isLoaded = ref(false);
    window.addEventListener("load", () => {
      new Rellax(".rellax");
      isLoaded.value = true;
    });
    return { isLoaded };
  },
});
